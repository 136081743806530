<template>
   <div>
    <topHead></topHead>
    <div class="recordWrap">
        <div style="text-align: center;font-size: 24px;color: #333;height: 50px;line-height:50px;font-weight: bold;">学习记录</div>

            <el-table  v-loading="loading"
                :data="tableData" :cell-style="rowStyle"
                style="width: 100%">
                
                <el-table-column
                    prop="expertName"
                    label="用户"
                    align="center"
                    >
                </el-table-column>
                <!-- <el-table-column  v-if="userType == '7'"
                    prop="expertName"
                    label="手机号"
                    align="center"
                    >
                    {{ userInfo.telephone}}
                </el-table-column>
                <el-table-column v-if="userType == '7'"
                    prop="expertName"
                    label="身份证号"
                    align="center"
                    >
                    {{ userInfo.idCard }}
                </el-table-column>
                <el-table-column v-if="userType == '7'"
                    prop="expertName"
                    label="工作单位"
                    align="center"
                    >
                    {{ userInfo.comName }}
                </el-table-column> -->
                <el-table-column
                    prop="bokName"
                    label="知识体系名称"
                    align="center"
                    >
                </el-table-column>
                <!-- <el-table-column
                    prop="subjectName"
                    label="科目名称"
                    align="center"
                    >
                </el-table-column> -->
                <el-table-column
                    prop="lessonName"
                    label="所学课程名称"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="beginTime"
                    label="学习开始时间"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="endTime"
                    label="学习结束时间"
                    align="center"
                    >
                </el-table-column>

                <el-table-column
                    label="学习完成" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.complete == 0">否</span>
                        <span v-if="scope.row.complete == 1">是</span>
                    </template>   
                </el-table-column>
                <el-table-column
                     align="center"
                    label="暂停进度">
                    <template slot-scope="scope" v-if="scope.row.playbackPercent">
                        {{ scope.row.playbackPercent }}%
                    </template> 
                </el-table-column>
                <el-table-column
                    align="center"
                    label="状态">
                    <template slot-scope="scope" >
                        <span v-if="scope.row.state == 0 || scope.row.state == null ">学习中</span>
                        <span v-if="scope.row.state == 1">初审通过</span>
                        <span v-if="scope.row.state == 2">学习完成</span>
                        <span v-if="scope.row.state == 3">初审不通过</span>
                        <span v-if="scope.row.state == 5">复审通过</span>
                        <span v-if="scope.row.state == 6">复审不通过</span>
                    </template> 
                </el-table-column>
                <el-table-column v-if="userType != '7'"
                    align="center"
                    label="详情">
                    <template slot-scope="scope" >
                        <el-button type="primary" sizi="mini" @click="handleClick(scope.row)">查看详情</el-button>
                    </template> 
                </el-table-column>
            </el-table>

            <el-pagination class="mt20" background style="text-align:center;margin-top:20px;" v-if="(total>10)"
                    @current-change="handleCurrentChange" 
                    :current-page="params.pageNum" 
                    :total="total"></el-pagination>

            <!-- <el-button type="primary" @click="exprot" style="margin-top:20px;">导出记录</el-button> -->
        </div>
        <el-dialog :show-close="false"
            title="详情"
            :visible.sync="dialogVisible"
            width="30%" center>
            <ul class="infoUl">
                <li>培训初审状态: <span v-if="msgInfo.auditStatus">{{ msgInfo.auditStatus == 1 ? '初审通过' : msgInfo.auditStatus == 3 ? '初审不通过' : '' }}</span></li>
                <li>初审时间:<span v-if="msgInfo.auditTime">{{ msgInfo.auditTime}} </span></li>
                <li>初审备注:<span v-if="msgInfo.auditMemo">{{ msgInfo.auditMemo}} </span></li>
                <li>初审审核人:<span v-if="msgInfo.auditUserName">{{ msgInfo.auditUserName}} </span></li>
            </ul>
            <ul class="infoUl">
                <li>培训复审状态:<span v-if="msgInfo.reviewStatus">{{ msgInfo.reviewStatus == 5 ? '复审通过' : msgInfo.reviewStatus == 6 ? '复审不通过' : '' }}</span></li>
                <li>复审时间:<span v-if="msgInfo.reviewTime">{{ msgInfo.reviewTime}} </span></li>
                <li>复审备注:<span v-if="msgInfo.reviewMemo">{{ msgInfo.reviewMemo}} </span></li>
                <li>复审审核人:<span v-if="msgInfo.reviewUserName">{{ msgInfo.reviewUserName}} </span></li>
            </ul>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false;msg=null;">确 定</el-button>
            </span>
            </el-dialog>
    <foot></foot>
   </div>
</template> 
<script>
import netTool from '../../api/netTool';
    import foot  from '../../components/footer.vue';
    import topHead  from '../../components/topHead.vue';
export default {
    
    data() {
        return {
            userType:localStorage.getItem('userType'),
            personName:'',
            tableData:[],
            total:0,
            params:{
                pageNum: 1,
                pageSize: 10,
                platformId: this.$store.state.pfId,
                expertUuid: localStorage.getItem('expertUuid'),
                category:localStorage.getItem('userType') == '3' ? 1: 2
            },
            loading:false,
            msgInfo:{},
            dialogVisible:false,
        }
    },
    components:{
        foot,
        topHead
    },
    created(){
        this.userInfo = this.$store.state.userInfo;
        this.personName = localStorage.getItem('uuid')
        this.getLearnList()
        document.title = "培训考试系统";
    },
    methods:{
        rowStyle(){
            return "text-align:center"
        },
        exprot(){
            this.$message({
                message: '导出记录成功！',
                type: 'success'
            });
        },
        handleClick(row) {
            console.log(row);
            this.dialogVisible = true;
            this.msgInfo = row;
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                this.msgInfo = {};
                done();
            })
            .catch(_ => {});
        },
        // 查询学习记录列表
        getLearnList(){
            this.loading = true;
            netTool.learnList(this.params).then(res => {
                this.tableData = res.data.rows
                this.total = res.data.total;
                this.loading = false;
            })
        },
        handleCurrentChange(val){
            this.params.pageNum = val;
            this.getLearnList();
        },
    }
   
}
</script>
<style scoped lang="scss">

.recordWrap{
    width: 1200px;
    margin:0 auto;
    padding-top: 50px;
    box-sizing: border-box;
    min-height:calc(100vh - 260px);
}
.infoUl{
    margin: 20px;
    li{
        line-height:30px;
        font-size: 14px;
    }
}
.el-dialog__header {
    background: #002a52;
    text-align: center;
    }
    .el-dialog__title {
    color: aliceblue;
    }



</style>
    