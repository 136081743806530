<template>
    <div class='footWrap'>
        <div class="conWrap">
            技术支持：自治区建设工程招投标协会信息技术发展部 0991-8837410
        </div>
    </div>
</template>

<script>
    export default{
        data(){
            return{
                
            }
        },

    }
</script>
<style scoped  lang="scss">
.footWrap{
    width:100%;
    height:200px; 
    line-height:200px;
    // background:RGB(63, 76, 101);
    text-align: center;
    .conWrap{
         width:1400px;
        margin:0 auto;
        color: #333;
    }
}
    
       
        
</style>